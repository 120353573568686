var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-content"},[_c('b-modal',{attrs:{"id":"confirm-remove-participant","hide-backdrop":"","content-class":"shadow","title":"Verwijderen"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.removeParticipant($event)}}},[_vm._v(" Ja ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return cancel()}}},[_vm._v(" Nee ")])]}}])},[_c('p',{staticClass:"my-4"},[_vm._v("Weet u zeker dat u deze deelnemer wilt verwijderen?")])]),_c('div',{staticClass:"card user-profile o-hidden mb-30"},[_c('div',{staticClass:"header-cover"}),_vm._m(0),_c('b-card',{staticClass:"wrapper"},[_c('care-recipient-full-table',{attrs:{"items":_vm.careRecipientItems,"on-item-click":_vm.onCareRecipientItemClick}}),_c('b-form-group',{staticClass:"col-md-8",attrs:{"label":"Legenda:","label-for":"input-1"}},[_c('div',[_c('b-icon',{attrs:{"icon":"circle-fill","variant":"danger"}}),_c('span',{staticClass:"m-2"},[_vm._v("Open aanvraag")])],1),_c('div',[_c('b-icon',{attrs:{"icon":"circle-fill","variant":"info"}}),_c('span',{staticClass:"m-2"},[_vm._v("Gekoppeld")])],1),_c('div',[_c('b-icon',{attrs:{"icon":"circle-fill","variant":"success"}}),_c('span',{staticClass:"m-2"},[_vm._v("Afgehandeld")])],1),_c('div',[_c('b-icon',{attrs:{"icon":"circle-fill","color":"#888"}}),_c('span',{staticClass:"m-2"},[_vm._v("Niet in te vullen")])],1),_c('div',[_c('b-icon',{attrs:{"icon":"circle-fill","variant":"warning"}}),_c('span',{staticClass:"m-2"},[_vm._v("Niet doorgegaan")])],1)]),_c('b-modal',{attrs:{"id":"care-recipient-item-modal","size":"xl","ok-title":_vm.okLabel,"cancel-title":_vm.cancelLabel,"title":"Hulpvraag"},on:{"ok":_vm.saveCareRecipientItem},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){$event.preventDefault();return ok()}}},[_vm._v(" Opslaan ")]),(_vm.careRecipientItem._id)?_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.showConfirmRemoveCareRecipientItem}},[_vm._v(" Verwijderen ")]):_vm._e(),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return cancel()}}},[_vm._v(" Sluiten ")])]}}])},[_c('b-card',[_c('b-modal',{attrs:{"id":"confirm-remove-carerecipient-item","hide-backdrop":"","content-class":"shadow","title":"Verwijderen"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.removeCareRecipientItem($event)}}},[_vm._v(" Ja ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return cancel()}}},[_vm._v(" Nee ")])]}}])},[_c('p',{staticClass:"my-4"},[_vm._v("Weet u zeker dat u dit item wilt verwijderen?")])]),_c('care-recipient-item',{attrs:{"care-recipient-item":_vm.careRecipientItem}})],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-info"},[_c('img',{staticClass:"profile-picture avatar-lg mb-2",attrs:{"src":require("@/assets/images/default.png"),"alt":""}}),_c('p',{staticClass:"m-0 text-24"},[_vm._v("Hulpaanvragen")])])}]

export { render, staticRenderFns }